@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-brand-dark;
  }
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("../../public/fonts/GilroyMedium.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("../../public/fonts/GilroyRegular.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  src: url("../../public/fonts/GilroyLight.woff2") format("woff2");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  src: url("../../public/fonts/GilroyThin.woff2") format("woff2");
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

@layer components {
  .pressable-card {
    @apply bg-white border border-dark-grey border-opacity-20 rounded p-2;
    @apply shadow-sm hover:shadow;
  }
}
